<template>
  <div class="container">
    <div class="columns bm-column-container mt-1">
      <div class="column"></div>
      <div class="column">
        <div class="is-flex is-justify-content-center is-align-items-center is-flex-direction-column">
          <separator class="separator is-primary"></separator>
          <h2 class="title is-2 has-text-centered">
            {{ $t("business_matching.business_matching") }}
          </h2>
        </div>
      </div>
      <div class="column">
        <div class="bm-instruction-buttons has-text-centered mt-3">
          <router-link class="button is-primary has-fullwidth" :to="{ name: Constants.ROUTE_AVAILABILITY }">
            <span class="icon">
              <calendar-icon class="svg-icon has-fill-link-text"></calendar-icon>
            </span>
            <div>
              {{ $t("business_matching.availability_button") }}
            </div>
          </router-link>
          <button class="button is-primary mt-1 has-fullwidth" @click="createNewMeeting" v-if="isCreateNewMeetingVisible">
            <span class="icon">
              <meeting-icon class="svg-icon has-primary-text-fill"></meeting-icon>
            </span>
            <div>
              {{ $t("business_matching.business_matching_new_meeting_title") }}
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="columns is-centered mt-2 bm-container">

      <div class="column is-one-third">
        <div class="container is-fluid bm-column-container">
          <h2 class="title is-3 has-text-centered mb-4">{{$t("business_matching.business_matching_instruction_title")}}</h2>
          <div class="bm-instruction-text has-text-centered">
            {{$t("business_matching.business_matching_instruction_description")}}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="bm-column-container">
          <b-tabs v-model="tabIndex" position="is-centered" expanded>
            <b-tab-item
              :label="$t('business_matching.business_matching_accepted_uppercase') + ` (` + acceptedMeetingsCount + `)`"
            ></b-tab-item>
            <b-tab-item
              :label="$t('business_matching.business_matching_pending_uppercase') + ` (` + pendingMeetingsCount + `)`"
            ></b-tab-item>
          </b-tabs>
          <router-view class="has-fullheight"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Constants from "@/web/constants";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import MeetingIcon from "@/assets/icons/business_matching.svg";
import CalendarIcon from "@/assets/icons/calendar_copy.svg";
import Separator from "@/web/components/shared/Separator";

export default {
  name: "BusinessMatching",

  components: {MeetingIcon, CalendarIcon, Separator},

  mixins: [AuthorizedRouteMixin],

  data() {
    return {
      tabIndex: 0,
    };
  },

  computed: {
    ...mapGetters("businessMatchingMeetings", ["acceptedMeetingsCount", "pendingMeetingsCount"]),
    ...mapGetters("components", ["businessMatchingComponents"]),

    Constants: () => Constants,

    isCreateNewMeetingVisible() {
      return this.businessMatchingComponents && this.businessMatchingComponents.length;
    }
  },

  methods: {
    createNewMeeting() {
      this.$root.openBMModal({components: this.businessMatchingComponents});
    },
  },

  watch: {
    tabIndex: {
      immediate: false,
      handler: function (newValue) {
        if (newValue === tabIndexes.PENDING && this.$route.name !== Constants.ROUTE_BUSINESS_MATCHING_PENDING) {
          this.$router.replace({name: Constants.ROUTE_BUSINESS_MATCHING_PENDING});
        } else if (newValue === tabIndexes.ACCEPTED && this.$route.name !== Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED) {
          this.$router.replace({name: Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED});
        }
      },
    },
    "$route.name": {
      immediate: true,
      handler: function (newValue) {
        if (this.$route.name === Constants.ROUTE_BUSINESS_MATCHING_PENDING) {
          this.tabIndex = 1;
        } else if (this.$route.name === Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED) {
          this.tabIndex = 0;
        } else if (this.$route.name === Constants.ROUTE_BUSINESS_MATCHING) {
          this.$router.replace({name: Constants.ROUTE_BUSINESS_MATCHING_ACCEPTED});
        }
      },
    },
  },

  mounted() {
  },
};

const tabIndexes = {
  ACCEPTED: 0,
  PENDING: 1,
};
</script>

<style scoped lang="scss">
.has-primary-text-fill {
  fill: var(--primary-color-text);
}

.has-fill-link-text {
  fill: var(--link-color-text);
}
</style>
