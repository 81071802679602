<template>
  <div>
    <b-loading :active="isRequesting" :is-full-page="true"></b-loading>
    <div class="columns is-mobile is-gapless is-centered mb-0 is-multiline">
      <div class="column" v-if="!isAccepted && isFromCurrentUser">
        <div class="pending-text">
          {{ $t("business_matching.business_matching_pending_dots_uppercase") }}
        </div>
      </div>
      <div class="column" v-if="!hasTime && !isFromCurrentUser">
        <button class="button is-text" @click="chooseTime">
          <span class="icon">
            <time-icon class="time-icon"></time-icon>
          </span>
          <div>
            {{ $t("business_matching.business_matching_choose_time_uppercase") }}
          </div>
        </button>
      </div>
      <div class="column" v-else-if="!isAccepted && !isFromCurrentUser">
        <button class="button is-text" @click="onAcceptClick">
          <span class="icon">
            <accept-icon class="svg-icon accept-icon"></accept-icon>
          </span>
          <div>
            {{ $t("common.accept_capital_letters") }}
          </div>
        </button>
      </div>
      <div class="column" v-if="isAccepted">
        <button class="button is-text" @click="openInbox">
          <span class="icon">
            <message-icon class="svg-icon message-icon"></message-icon>
          </span>
          <div>
            {{ $t("business_matching.business_matching_lets_talk_uppercase") }}
          </div>
        </button>
      </div>
      <div class="column" v-if="hasTime">
        <button class="button is-text" @click="reschedule">
          <span class="icon">
            <settings-icon class="svg-icon reschedule-icon"></settings-icon>
          </span>
          <div>
            {{ $t("business_matching.business_matching_reschedule_uppercase") }}
          </div>
        </button>
      </div>
      <div v-else class="if-flex is-flex-grow-1"></div>

      <div class="column">
        <button class="button is-text" @click="onCancelClick">
          <span class="icon">
            <cancel-icon class="svg-icon cancel-icon"></cancel-icon>
          </span>
          <div>
            {{ $t("common.cancel_capital_letters") }}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TimeIcon from "@/assets/icons/time.svg";
import SettingsIcon from "@/assets/icons/settings_bar.svg";
import AcceptIcon from "@/assets/icons/accept.svg";
import CancelIcon from "@/assets/icon_close.svg";
import Constants from "@/web/constants";
import MessageIcon from "@/assets/icons/chat.svg";
import {mapActions, mapGetters} from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import BMCancelMeetingModal from "@/web/components/businessmatching/meeting/BMCancelMeetingModal";

export default {
  name: "BMMeetingButtons",

  components: {TimeIcon, SettingsIcon, AcceptIcon, CancelIcon, MessageIcon},

  mixins: [RequestMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    isNotification: {
      type: Boolean,
      required: true,
    },

    currentUserUuid: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters("components", ["getComponentById"]),

    meetingId() {
      return this.meeting.id;
    },

    isAccepted() {
      return this.meeting.status === Constants.BUSINESS_MATCHING_INVITATION_STATUS_ACCEPTED;
    },

    isFromCurrentUser() {
      return this.meeting.from_user_id === this.currentUserUuid;
    },

    meetingUser() {
      return this.isFromCurrentUser ? this.meeting.to_user : this.meeting.from_user;
    },

    hasTime() {
      return !!this.meeting.time_start;
    },
  },

  methods: {
    ...mapActions("businessMatchingMeetings", ["acceptMeeting"]),
    ...mapActions("inboxThreads", ["checkIfThreadExists"]),
    ...mapActions("sideMenu", ["openInboxBubbleWithThread", "openInboxBubbleWithUser"]),

    onCancelClick() {
      this.$buefy.modal.open({
        parent: this,
        component: BMCancelMeetingModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: {
          meeting: this.meeting,
        },
      });
    },

    onAcceptClick() {
      if (!this.isRequesting) {
        this.accept();
      }
    },

    openInbox() {
      const userUuid = this.meetingUser.user_identity_token;
      this.checkIfThreadExists({userId: userUuid}).then(result => {
        if (result.data) {
          const threadId = result.data.id;
          this.openInboxBubbleWithThread(threadId);
        } else {
          this.openInboxBubbleWithUser(userUuid);
        }
      });
    },

    reschedule() {
      this.openBMModal();
    },

    chooseTime() {
      this.openBMModal();
    },

    openBMModal() {
      const component = this.getComponentById(this.meeting.event_component_id);
      if (component) {
        this.$root.openBMModal({components: [component], meeting: this.meeting});
      }
    },
  },

  requests: {
    async accept() {
      await this.acceptMeeting({componentId: this.meeting.event_component_id, meetingId: this.meeting.id});
    },

    async decline() {
      await this.declineMeeting({componentId: this.meeting.event_component_id, meetingId: this.meeting.id});
    },

    async delete() {
      await this.deleteMeeting({componentId: this.meeting.event_component_id, meetingId: this.meeting.id});
    },
  },

  watch: {
    error: {
      immediate: false,
      handler: function (newVal) {
        if (newVal) {
          const errorMessage = this.$options.filters.pluckError(newVal);
          this.$root.openErrorModal(errorMessage);
        }
      },
    },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-size: 11px;
  font-weight: bold;
  height: 40px;
}

.pending-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fec602;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
}

.reschedule-icon {
  fill: #fec602;
}

.cancel-icon {
  stroke: #ff3247;
}

.accept-icon {
  fill: #73d501;
}

.time-icon {
  fill: #00aeef;
}

.message-icon {
  fill: #00aeef;
}

.icon {
  height: 26px;
  width: 26px;
}

@media screen and (max-width: 769px) {
  .icon {
    height: 20px;
    width: 20px;
  }

  .button {
    font-size: 10px;
  }
}

.column {
  display: flex;
  justify-content: center;
}
</style>
