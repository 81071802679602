<template>
  <div class="bm-meeting-container box has-logo">
    <div class="bm-meeting-top-container px-2" v-if="locationAndTimeVisible">
      <time-icon class="bm-meeting-top-container-icon"></time-icon>
      <span class="bm-meeting-top-container-text">
        {{ formattedTimeDuration }}
      </span>

      <location-icon class="bm-meeting-top-container-icon ml-2"></location-icon>
      <div class="bm-meeting-top-container-text">
        {{ location }}
      </div>
    </div>
    <div class="media px-2 bm-meeting-profile-container">
      <div class="media-left">
        <profile-picture
          @click.native="openUserProfile()"
          class="is-40x40 bm-meeting-user-avatar"
          :picture="meetingUserPicture"
          :initials="meetingUserInitials"
        ></profile-picture>
      </div>

      <div class="media-content">
        <p v-if="meetingUserFullName" class="title">{{ meetingUserFullName }}</p>
        <p v-if="meetingUserDescription" class="subtitle">{{ meetingUserDescription }}</p>
      </div>

<!--      <div class="media-right">-->
<!--        <b-button class="button bm-meeting-video-call-button p-0" v-if="isVideoCallEnabled" @click="startVideoCall">-->
<!--          <div class="bm-meeting-video-call-icon">-->
<!--            <video-call-icon class="bm-meeting-video-call-icon has-fill-primary"></video-call-icon>-->
<!--          </div>-->
<!--        </b-button>-->
<!--      </div>-->
    </div>
    <div v-if="message" class="bm-meeting-message">
      {{ message }}
    </div>
    <div class="bm-buttons-separator"></div>
    <b-m-meeting-buttons :meeting="meeting" :is-notification="false"
                         :current-user-uuid="currentUserUuid"></b-m-meeting-buttons>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import BMMeetingButtons from "@/web/components/businessmatching/meeting/BMMeetingButtons";
import LocationIcon from "@/assets/icons/geo_position.svg";
import TimeIcon from "@/assets/icons/time.svg";
import {composeSessionRange} from "@/shared/utils";
import UserProfileModal from "@/web/components/profile/UserProfileModal";
import {fullName, positionAndCompany, initials} from "@/shared/utils";
import AuthorizedRouteMixin from "@/web/mixins/AuthorizedRouteMixin";
import ProfilePicture from "@/shared/components/common/profile/ProfilePicture";
import Constants from "@/web/constants";

export default {
  name: "BMMeetingListItem",

  components: {ProfilePicture, BMMeetingButtons, LocationIcon, TimeIcon},

  mixins: [AuthorizedRouteMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    currentUserUuid: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState(["timezone"]),
    ...mapGetters("businessMatching", ["isVideoCallEnabledInComponent"]),

    componentId() {
      return this.meeting.event_component_id;
    },

    location() {
      return (this.meeting.tag_agenda_place && this.meeting.tag_agenda_place.value) || this.meeting.custom_place_name;
    },

    formattedTimeDuration() {
      return composeSessionRange(this.meeting.time_start, this.meeting.time_end, this.timezone);
    },

    locationAndTimeVisible() {
      return !!(this.location && this.formattedTimeDuration);
    },

    message() {
      return this.meeting.message;
    },

    meetingUser() {
      return this.meeting.to_user_id === this.currentUserUuid ? this.meeting.from_user : this.meeting.to_user;
    },

    meetingUserFullName() {
      return fullName(this.meetingUser);
    },

    meetingUserInitials() {
      return initials(this.meetingUser);
    },

    meetingUserDescription() {
      return positionAndCompany(this.meetingUser);
    },

    meetingUserPicture() {
      return this.meetingUser.picture;
    },

    isAccepted() {
      return this.meeting.status === Constants.BUSINESS_MATCHING_INVITATION_STATUS_ACCEPTED;
    },

    isVideoCallEnabled() {
      return this.isAccepted && this.isVideoCallEnabledInComponent(this.componentId);
    },
  },

  methods: {
    ...mapActions("currentUser", ["joinVideoCallWithUser"]),

    openUserProfile() {
      this.$buefy.modal.open({
        parent: this,
        component: UserProfileModal,
        hasModalCard: true,
        canCancel: true,
        trapFocus: true,
        props: {
          userId: this.meetingUser.user_identity_token,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.bm-meeting-container {
}

.bm-meeting-top-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 24px;
  max-height: 24px;
  margin-top: 8px;
}

.bm-meeting-top-container-icon {
  fill: #939393;
  height: 24px;
  width: 24px;
}

.bm-meeting-top-container-text {
  color: #939393;
  font-size: 12px;
  padding-top: 2px;
  margin-left: 8px;
}

.bm-meeting-profile-container {
  margin-top: 8px;
}

.bm-meeting-user-avatar {
  cursor: pointer;
}

.title {
  font-size: 16px;
}

.subtitle {
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.media {
  align-items: center;
}

.bm-meeting-video-call-button {
  height: 40px !important;
  width: 40px !important;
}

.bm-meeting-video-call-icon {
  height: 30px;
  width: auto;
}

.bm-buttons-separator {
  margin-top: 10px;
  height: 1px;
  width: auto;
  margin-left: 18px;
  margin-right: 18px;
  background-color: #f0f0f0;
}

.bm-meeting-message {
  font-size: 16px;
  white-space: pre-wrap;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 10px;
}
</style>
